import React from 'react';
import { AssistantIcon } from '../../../assets';
import { ConversationCardProps } from './conversationModels';
import { formatFullDateTime } from '../../../helpers/dateHelpers';

const ConversationCard: React.FC<ConversationCardProps> = ({ chat }) => {
  return (
    <div className="d-flex-start-start w-100 m-0_5rem-0 p-1_125rem-1_375rem bg-white b-radius-1_25rem b-1">
      <div className="d-flex-row-space-between w-100 pb-1rem bb-1">
        <div className="d-flex-row ">
          <div className="d-flex-center bg-f1f1f5 w-3rem h-3rem b-radius-1_5rem mr-1_25rem">
            <AssistantIcon className="w-1rem" />
          </div>
          <h4 className="ff-poppins c-regular fs-1_125rem fw-600">
            Conversation with {chat?.Lead?.firstname} {chat?.Lead?.lastname}
          </h4>
        </div>
        <div className="d-flex-row">
          <div className="d-flex-center mr-1rem b-1 p-0_25rem-1rem b-radius-2_125rem">
            <span className="f-roboto-primary fs-0_875rem lh-1_5rem">
              {chat?.Lead?.email}
            </span>
          </div>
          <span className="f-roboto-secondary fs-0_75rem">
            {formatFullDateTime(chat?.createdAt)}
          </span>
        </div>
      </div>
      <div className="d-flex-start-start pt-1rem w-100">
        <span className="ff-roboto c-50b5ff fs-0_875rem lh-1_5rem fw-600 ls-0_00625rem">
          {chat?.Campaign?.name}
        </span>
        <p className="ff-roboto c-696974 fs-0_875rem lh-1_5rem ls-0_00625rem">
          {chat?.summary ? (
            chat?.summary
          ) : (
            <>
              {typeof chat?.lastMessage === 'object' &&
              'text' in chat.lastMessage
                ? chat.lastMessage.text
                : chat?.lastMessage}
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default ConversationCard;
