import React, { useState, useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { TriangleIcon } from '../../../assets';
import { ConversationListFilterDropdownProps } from './conversationModels';
import { ConversationListStatusOptions } from '../../../config/CampaignsConfig';

const ConversationListFilterDropdown: React.FC<
  ConversationListFilterDropdownProps
> = ({ setFilters, filters }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const showDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    showDropdownRef,
    () => setShowDropdown(false),
    excludeOutsideClickRef
  );

  const handleStatusChange = (status: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status,
    }));
    setShowDropdown(false);
  };

  return (
    <div className="d-flex-row p-relative">
      <div
        className="d-flex-row pointer"
        onClick={() => setShowDropdown(!showDropdown)}
        ref={excludeOutsideClickRef}
      >
        <span className="f-roboto-secondary fs-1_125rem">Show:</span>
        <div className="d-flex-row">
          <span className="sort-dropdown-item pl-0_375rem">
            {ConversationListStatusOptions.find(
              (option) => option.value === filters.status
            )?.text || 'All'}
          </span>
          <div className="d-flex-center pl-0_375rem">
            <TriangleIcon
              className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
            />
          </div>
        </div>
      </div>
      {showDropdown && (
        <div
          className="p-absolute left-0 top-1_5rem z1 bg-white mw-12rem  shadow-box-12"
          ref={showDropdownRef}
        >
          {ConversationListStatusOptions.map((option) => (
            <div
              className="d-flex-center-start h-2_375rem bt-1 hovered"
              key={option.value}
              onClick={() => handleStatusChange(option.value)}
            >
              <span className="sort-dropdown-item pl-0_75rem">
                {option.text}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ConversationListFilterDropdown;
