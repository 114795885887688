import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../context/AuthContext';
import { getUser, updateUser } from '../../core/requests/requests';
import {
  emailValidationSchema,
  firstNameValidationSchema,
} from '../auth/components/ValidationSchemaItems';
import { UserProfileProps } from '../../core/types/_models';
import { ProfileHeader } from './components';

const Profile: React.FC = () => {
  const { currentUser, authLogin, setCurrentUser } = useAuth();
  const [focusedFields, setFocusedFields] = useState({
    firstName: false,
    email: false,
    jobPosition: false,
    companyName: false,
    address: false,
    linkedinUrl: false,
    about: false,
    campaignContext: false,
  });

  const initialValues = {
    id: currentUser?.id ? currentUser?.id : '',
    firstName: currentUser?.firstName ? currentUser?.firstName : '',
    email: currentUser?.email ? currentUser?.email : '',
    jobPosition: currentUser?.jobPosition ? currentUser?.jobPosition : '',
    companyName: currentUser?.companyName ? currentUser?.companyName : '',
    address: currentUser?.address ? currentUser?.address : '',
    linkedinUrl: currentUser?.linkedinUrl ? currentUser?.linkedinUrl : '',
    about: currentUser?.about ? currentUser?.about : '',
    campaignContext: currentUser?.campaignContext
      ? currentUser?.campaignContext
      : '',
    askAdditionalInfo: false,
    isFacebookLoginIn: currentUser?.isFacebookLoginIn ?? false,
    isHubspotLoginIn: currentUser?.isHubspotLoginIn ?? false,
  };

  const validationSchema = Yup.object().shape({
    firstName: firstNameValidationSchema,
    email: emailValidationSchema,
  });

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const handleSubmit = async (values: UserProfileProps) => {
    try {
      const userId = authLogin?.userId;
      const accessToken = authLogin?.accessToken;
      if (accessToken && userId) {
        const { data } = await updateUser(accessToken, userId, values);
        if (data) {
          const response = await getUser(userId, accessToken);
          setCurrentUser(response.data);
        }
      }
    } catch {
      console.log('Updating User Info failed');
    }
  };

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <div className="d-flex-start-start b-radius-3rem bg-white w-100 mt-1rem p-3_125rem-4_125rem bg-white mb-3rem">
        <ProfileHeader />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, isValid }) => (
            <Form className="w-100">
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="firstName"
                    className={`form-control ${errors.firstName ? 'error-border' : ''} `}
                    placeholder="First Name"
                    onFocus={() => handleFieldFocus('firstName')}
                    onBlur={() => handleFieldBlur('firstName')}
                  />
                  {errors.firstName && (
                    <div className="form-error">{errors.firstName}</div>
                  )}
                  {focusedFields.firstName && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.firstName ? 'error-title' : 'correct-title'}`}
                      >
                        First Name
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${errors.email ? 'error-border' : ''} `}
                    placeholder="Your Email Address"
                    onFocus={() => handleFieldFocus('email')}
                    onBlur={() => handleFieldBlur('email')}
                  />
                  {errors.email && (
                    <div className="form-error">{errors.email}</div>
                  )}
                  {focusedFields.email && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.email ? 'error-title' : 'correct-title'}`}
                      >
                        Your Email Address
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="jobPosition"
                    className="form-control"
                    placeholder="Your Job/Position"
                    onFocus={() => handleFieldFocus('jobPosition')}
                    onBlur={() => handleFieldBlur('jobPosition')}
                  />
                  {focusedFields.jobPosition && (
                    <div className="d-flex-center title-container">
                      <span className="form-title  correct-title">
                        Your Job/Position
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="companyName"
                    className="form-control"
                    placeholder="Your Company"
                    onFocus={() => handleFieldFocus('companyName')}
                    onBlur={() => handleFieldBlur('companyName')}
                  />
                  {focusedFields.companyName && (
                    <div className="d-flex-center title-container">
                      <span className="form-title correct-title">
                        Your Company
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex-row-space-between form-row mb-1_75rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Your City, State"
                    onFocus={() => handleFieldFocus('address')}
                    onBlur={() => handleFieldBlur('address')}
                  />
                  {focusedFields.address && (
                    <div className="d-flex-center title-container">
                      <span className="form-title  correct-title">
                        Your City, State
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="linkedinUrl"
                    className="form-control"
                    placeholder="Your LinkedIn URL"
                    onFocus={() => handleFieldFocus('linkedinUrl')}
                    onBlur={() => handleFieldBlur('linkedinUrl')}
                  />
                  {focusedFields.linkedinUrl && (
                    <div className="d-flex-center title-container">
                      <span className="form-title correct-title">
                        Your LinkedIn URL
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 mb-1_75rem">
                <span className="fs-0_875rem">Tell us about yourself</span>
                <div className="form-item  w-100 mt-0_75rem">
                  <Field
                    type="text"
                    name="about"
                    component="textarea"
                    className="textarea-control"
                    placeholder="Hello my name..."
                    onFocus={() => handleFieldFocus('about')}
                    onBlur={() => handleFieldBlur('about')}
                  />
                  {/*focusedFields.about && (
              <div className="d-flex-center title-container">
                <span className="form-title correct-title">
                  Hello my name...
                </span>
              </div>
            )*/}
                </div>
              </div>
              {/*<div className="w-100 mb-2_375rem">
          <span className="fs-0_875rem">Give us some context</span>
          <div className="form-item  w-100 mt-0_75rem">
            <Field
              type="text"
              name="campaignContext"
              component="textarea"
              className="textarea-control"
              placeholder="Give us some context - what's your campaign all about?"
              onFocus={() => handleFieldFocus('campaignContext')}
              onBlur={() => handleFieldBlur('campaignContext')}
            />
            {focusedFields.about && (
              <div className="d-flex-center title-container">
                <span className="form-title correct-title">
                  Hello my name...
                </span>
              </div>
        )}
          </div>
        </div>*/}
              <div className="d-flex-center-end-row  w-100">
                <button
                  type="submit"
                  className="btn btn-light-primary w-5rem"
                  disabled={!isValid}
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
