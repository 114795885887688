import React, { useEffect, useState, useRef } from 'react';
import { useAppData } from '../../../context/AppContext';
import { useAuth } from '../../../context/AuthContext';
import {
  getAllTags,
  getLeads,
  updateCampaignLeads,
} from '../../../core/requests/requests';
import { ChevronDownIcon, CloseIcon, SearchIcon } from '../../../assets';
import { LeadsListProps, LeadsTagsProps } from '../../../core/types/_models';
import useOutsideClick from '../../../hooks/useOutsideClicks';

interface AddLeadsToCampaignModalProps {
  closeModal: () => void;
  connectedLeads: LeadsListProps[];
  setConnectedToCampaignLeads: React.Dispatch<
    React.SetStateAction<LeadsListProps[]>
  >;
}

const AddLeadsToCampaignModal: React.FC<AddLeadsToCampaignModalProps> = ({
  closeModal,
  connectedLeads,
  setConnectedToCampaignLeads,
}) => {
  const { leadsList, currentCampaign, setLeadsList } = useAppData();
  const { currentUser } = useAuth();
  const [chosenLeadsIds, setChosenLeadsIds] = useState<string[]>([]);
  const [filteredLeadsList, setFilteredLeadsList] = useState<LeadsListProps[]>(
    []
  );
  const [isTagsDropdownOpen, setIsTagsDropdownOpen] = useState<boolean>(false);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [tagsList, setTagsList] = useState<LeadsTagsProps[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const currentCampaignId = currentCampaign?.id;
  const currentUserId = currentUser?.id;

  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    filterDropdownRef,
    () => setIsTagsDropdownOpen(false),
    excludeOutsideClickRef
  );

  useEffect(() => {
    // Update chosenLeadsIds based on the selectAll state
    if (selectAll && leadsList) {
      setChosenLeadsIds(leadsList.map((lead) => lead.id));
    } else {
      setChosenLeadsIds([]);
    }
  }, [selectAll, leadsList]);

  const handleCheckboxChange = (leadId: string, isChecked: boolean) => {
    if (isChecked) {
      setChosenLeadsIds((prev) => [...prev, leadId]);
    } else {
      setChosenLeadsIds((prev) => prev.filter((id) => id !== leadId));
    }
  };

  const triggerTagsDropdown = () => {
    setIsTagsDropdownOpen(!isTagsDropdownOpen);
  };

  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
  };

  const handleAddLeadsToCampaign = async () => {
    try {
      if (currentCampaignId && chosenLeadsIds.length > 0) {
        const { data } = await updateCampaignLeads(
          currentCampaignId,
          chosenLeadsIds
        );
        if (data) {
          const connectedLeads = data.map((item) => item.lead);
          setConnectedToCampaignLeads(connectedLeads);
          closeModal();
        }
      }
      //addLeadToCampaignById()
    } catch (error) {
      console.log(error);
    }
    // Add chosen leads to campaign
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  const handleTagSelection = (tagId: string) => {
    setSelectedTagIds((prevSelected) =>
      prevSelected.includes(tagId)
        ? prevSelected.filter((id) => id !== tagId)
        : [...prevSelected, tagId]
    );
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (currentUserId) {
          const { data } = await getAllTags(currentUserId);
          if (data) {
            setTagsList(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTags();

    if (leadsList && leadsList?.length > 0) {
      return;
    }
    const fetchData = async () => {
      try {
        if (currentUserId) {
          const response = await getLeads(currentUserId);
          if (response?.data) {
            setLeadsList((prevLeads) => {
              if (!prevLeads) return response.data; // return the new leads if prevLeads is null or undefined
              const existingIds = new Set(prevLeads.map((lead) => lead.id));
              const uniqueLeads = response.data.filter(
                (lead) => !existingIds.has(lead.id)
              );
              return [...prevLeads, ...uniqueLeads];
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [currentUserId]);

  useEffect(() => {
    if (leadsList && leadsList?.length > 0) {
      let filteredLeads = leadsList;

      // Filter based on selectedTagIds if any
      if (selectedTagIds.length > 0) {
        filteredLeads = filteredLeads.filter((lead) =>
          lead.Tags.some((tag) => selectedTagIds.includes(tag.id))
        );
      }

      // Filter based on searchInput if any
      if (searchInput.length > 0) {
        const lowerCaseInput = searchInput.toLowerCase();
        filteredLeads = filteredLeads.filter((lead) =>
          [
            lead.firstname,
            lead.lastname,
            lead.email,
            lead.city,
            lead.company,
            lead.phone,
          ].some((field) => field?.toLowerCase().includes(lowerCaseInput))
        );
      }

      setFilteredLeadsList(filteredLeads);
    }
  }, [leadsList, searchInput, selectedTagIds]);

  useEffect(() => {
    if (connectedLeads && connectedLeads.length > 0) {
      const leadIds = connectedLeads.map((lead) => lead.id);
      setChosenLeadsIds(leadIds);
    } else {
      setChosenLeadsIds([]);
    }
  }, [connectedLeads]);

  return (
    <div className="d-flex-start-between w-50rem h-80vh z4 bg-white  b-radius-0_5rem">
      <div className="d-flex-row-space-between w-100 p-1rem-1_5rem bb-1-l-gray">
        <span className="ff-poppins fw-500 fs-0_875rem c-regular">
          Select Leads:
        </span>
        <div className="d-flex-center icon-hovered" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <div className="d-flex-start-start w-100 h-calc-100-8rem">
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem ">
          <div className="d-flex-row w-calc-100_minus_10_5rem p-relative">
            <input
              className="settings-modal-input"
              placeholder="Search for Leads"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <div className="d-flex-center p-absolute top-0_8rem left-1rem">
              <SearchIcon />
            </div>
            {searchInput ? (
              <div
                className="d-flex-center p-absolute top-0_8rem right-1rem icon-hovered"
                onClick={handleClearInput}
              >
                <CloseIcon />
              </div>
            ) : null}
          </div>
          <div className="d-flex-start-start p-relative">
            <div
              className=" d-flex-row-space-between w-10rem p-0_5rem-1rem h-3rem b-radius-0_5rem b-1 hovered"
              onClick={triggerTagsDropdown}
              ref={excludeOutsideClickRef}
            >
              <span className="ff-inter fw-500 fs-0_875rem c-555f75">
                {selectedTagIds.length > 0
                  ? `Selected: ${selectedTagIds.length}`
                  : 'Filter by tags'}
              </span>
              <div className="d-flex-center">
                <ChevronDownIcon />
              </div>
            </div>
            {isTagsDropdownOpen ? (
              <div
                className="d-flex-start-start p-absolute top-3_5rem left-_-4rem bg-white z5 shadow-box-11 b-1-eaedf2 w-14rem p-1rem-0_75rem b-radius-0_5rem"
                ref={filterDropdownRef}
              >
                <span className="ff-inter fw-500 c-0f0f10 pb-0_5rem">Tags</span>
                <div className="d-flex-start-start w-100  max-h-25rem  overflow-auto">
                  {tagsList?.length > 0 ? (
                    <>
                      {tagsList?.map((tag) => (
                        <div
                          className="d-flex-row w-100 p-0_5rem-0"
                          key={tag?.id}
                        >
                          <input
                            className="tags-checkbox mr-0_5rem"
                            type="checkbox"
                            checked={selectedTagIds.includes(tag?.id)} // Check if the tag is selected
                            onChange={() => handleTagSelection(tag?.id)} // Handle the checkbox change
                          />
                          <span className="ff-inter fs-0_875rem c-323741">
                            {tag?.name}
                          </span>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="d-flex-start-start w-100">
                      <span className="ff-inter fs-0_875rem c-323741">
                        No tags added
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {filteredLeadsList && filteredLeadsList.length > 0 ? (
          <div className="d-flex-start-start w-100 p-0-1rem overflow-auto">
            <table className="agents-modal-table">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex-center">
                      <input
                        type="checkbox"
                        className="modal-checkbox"
                        checked={selectAll}
                        onChange={(e) =>
                          handleSelectAllChange(e.target.checked)
                        }
                      />
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredLeadsList.map((lead) => (
                  <tr key={lead.id}>
                    <td>
                      <div className="d-flex-center">
                        <input
                          type="checkbox"
                          name="useLeads"
                          className="modal-checkbox"
                          checked={chosenLeadsIds.includes(lead.id)}
                          onChange={(e) =>
                            handleCheckboxChange(lead.id, e.target.checked)
                          }
                        />
                      </div>
                    </td>
                    <td className="leads-table-name">{`${lead.firstname} ${lead.lastname}`}</td>
                    <td>{lead.email}</td>
                    <td>{lead.phone || 'N/A'}</td>
                    <td>{new Date(lead.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex-center w-100 p-1_5rem ">
            <span className="ff-inter fs-1_25rem fw-600 c-9ca3af">
              There are no matching leads
            </span>
          </div>
        )}
      </div>
      <div className="d-flex-row-space-between w-100 p-1rem-1_5rem shadow-box-10">
        <button
          className="btn-cancel w-calc-50_minus_0_25rem"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary w-calc-50_minus_0_25rem h-2_5rem"
          onClick={handleAddLeadsToCampaign}
          disabled={chosenLeadsIds.length === 0}
        >
          <span className="ff-inter fw-500 fs-0_875rem">Confirm</span>
        </button>
      </div>
    </div>
  );
};

export default AddLeadsToCampaignModal;
