import React, { useState, useEffect, useRef } from 'react';
import { AdCampaignSelectionModalProps } from './startCampaignModels';
import { CloseIcon, SearchIcon } from '../../../assets';
import { FacebookLeadsProps } from '../../../core/types/_models';
import { getFacebookLeads } from '../../../core/requests/requests';
import useOutsideClick from '../../../hooks/useOutsideClicks';

const AdCampaignSelectionModal: React.FC<AdCampaignSelectionModalProps> = ({
  closeModal,
  selectedFacebookCampaign,
  setSelectedFacebookCampaign,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(''); // Search query state
  const [facebookLeadsData, setFacebookLeadsData] = useState<
    FacebookLeadsProps[]
  >([]);
  const [
    temporarySelectedFacebookCampaign,
    setTemporarySelectedFacebookCampaign,
  ] = useState<FacebookLeadsProps | null>(selectedFacebookCampaign);
  const adCampaignModalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(adCampaignModalRef, () => closeModal());

  useEffect(() => {
    const fetchFacebookLeads = async () => {
      try {
        const { data } = await getFacebookLeads();
        if (data) {
          setFacebookLeadsData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchFacebookLeads();
  }, []);

  const handleCheckboxChange = (facebookLead: FacebookLeadsProps) => {
    setTemporarySelectedFacebookCampaign((prevSelected) =>
      prevSelected?.campaignId === facebookLead?.campaignId
        ? null
        : facebookLead
    );
  };

  const filteredFacebookData = facebookLeadsData?.filter((facebookLead) => {
    const searchValue = searchQuery?.toLowerCase();
    return (
      facebookLead?.campaignName?.toLowerCase().includes(searchValue) ||
      facebookLead?.campaignName?.toLowerCase().includes(searchValue)
    );
  });

  const handleConfirm = () => {
    if (temporarySelectedFacebookCampaign) {
      setSelectedFacebookCampaign(temporarySelectedFacebookCampaign);
    }
    closeModal();
  };

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div
        className="d-flex-start-between w-50rem h-80vh z4 bg-white b-radius-0_5rem"
        ref={adCampaignModalRef}
      >
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem bb-1-l-gray">
          <span className="ff-poppins fw-500 fs-0_875rem c-regular">
            Select Facebook Campaign Id:
          </span>
          <div className="d-flex-center icon-hovered" onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>
        {facebookLeadsData.length > 0 ? (
          <div className="d-flex-start-start w-100 h-calc-100-8rem">
            <div className="d-flex-center w-100 p-1rem-1_5rem">
              <div className="d-flex-row w-100 p-relative">
                <input
                  className="settings-modal-input"
                  placeholder="Search for Facebook Leads"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="d-flex-center p-absolute top-0_8rem left-1rem">
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className="d-flex-start-start w-100 pl-1_125rem overflow-auto">
              <table className="agents-modal-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Campaign Name</th>
                    <th>Facebook Ad Description</th>
                    <th>Facebook Ad name</th>
                    <th>Lead count</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFacebookData.map((facebookLead) => (
                    <tr key={facebookLead.campaignId}>
                      <td>
                        <input
                          type="checkbox"
                          className="modal-checkbox"
                          checked={
                            temporarySelectedFacebookCampaign?.campaignId ===
                            facebookLead.campaignId
                          } // Only one facebookLead can be selected
                          onChange={() => handleCheckboxChange(facebookLead)} // Handle checkbox change
                        />
                      </td>
                      <td>{facebookLead.campaignName}</td>
                      <td>{facebookLead.facebookAdDescription}</td>
                      <td>{facebookLead.facebookAdName}</td>
                      <td>{facebookLead.leadCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="d-flex-center w-100">
            <span className="ff-inter fw-600 fs-1_75rem c-clicks">
              No Facebook ADs found
            </span>
          </div>
        )}
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem shadow-box-10">
          <button
            className="btn-cancel w-calc-50_minus_0_25rem"
            onClick={closeModal}
          >
            <span>Cancel</span>
          </button>
          <button
            className="btn btn-primary w-calc-50_minus_0_25rem h-2_5rem"
            onClick={handleConfirm}
            disabled={!temporarySelectedFacebookCampaign}
          >
            <span className="ff-inter fw-500 fs-0_875rem">Confirm</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdCampaignSelectionModal;
