interface CampaignContactSpeedDropdownConfigProps {
  contactSpeed: string[];
}

export interface CampaignTimeZone {
  name: string;
  timeZone: string;
  value: number;
}

export interface CampaignTimeZonesDropdownConfigProps {
  timeZones: CampaignTimeZone[]; // An array of time zone objects
}

export interface StartCampaignConfigProps {
  value: string;
  campaignTitle: string;
  description: string | null;
  backButton: string | null;
  iconName: string | null;
  settingsButton: boolean;
  campaignsButton: boolean;
  addNewButton: boolean;
  underLine: boolean;
  showLeads: boolean;
}

export interface CampaignViewValuesProps {
  startCampaign: string;
  convertOnTheSpot: string;
  instagram: string;
  facebook: string;
  whatsapp: string;
  sms: string;
  approved_messages: string;
  add_new: string;
  send_templates: string;
  edit: string;
  appointment: string;
  reminder: string;
  custom: string;
  settings: string;
  chooseMedia: string;
}

export interface CampaignMessageTypesConfigProps {
  convertOnTheSpotType: string;
  reminderMessageType: string;
  nurtureLeadToAppointmentType: string;
}

export interface CampaignsFilterConfigProps {
  tags: string[];
}

export const CampaignContactSpeedDropdownConfig: CampaignContactSpeedDropdownConfigProps =
  {
    contactSpeed: ['Immediate', 'Option 1', 'Option 2'],
  };

export const CampaignTimeZonesDropdownConfig: CampaignTimeZonesDropdownConfigProps =
  {
    timeZones: [
      { name: 'London', timeZone: 'UTC+0', value: 0 },
      { name: 'Paris', timeZone: 'UTC+1', value: 1 },
      { name: 'Kyiv', timeZone: 'UTC+2', value: 2 },
      { name: 'Riyadh', timeZone: 'UTC+3', value: 3 },
      { name: 'Baku', timeZone: 'UTC+4', value: 4 },
      { name: 'Tashkent', timeZone: 'UTC+5', value: 5 },
      { name: 'Almaty', timeZone: 'UTC+6', value: 6 },
      { name: 'Jakarta', timeZone: 'UTC+7', value: 7 },
      { name: 'Perth', timeZone: 'UTC+8', value: 8 },
      { name: 'Tokyo', timeZone: 'UTC+9', value: 9 },
      { name: 'Sydney', timeZone: 'UTC+10', value: 10 },
      { name: 'Auckland', timeZone: 'UTC+12', value: 12 },
      { name: 'Samoa', timeZone: 'UTC-11', value: -11 },
      { name: 'Hawaii Time', timeZone: 'HST', value: -10 },
      { name: 'Alaska Time', timeZone: 'AKST', value: -9 },
      { name: 'Pacific Time', timeZone: 'PST', value: -8 },
      { name: 'Mountain Time', timeZone: 'MST', value: -7 },
      { name: 'Central Time', timeZone: 'CST', value: -6 },
      { name: 'Eastern Time', timeZone: 'EST', value: -5 },
      { name: 'Santiago', timeZone: 'UTC-4', value: -4 },
      { name: 'Sao Paulo', timeZone: 'UTC-3', value: -3 },
      { name: 'Stanley', timeZone: 'UTC-2', value: -2 },
      { name: 'Azores', timeZone: 'UTC-1', value: -1 },
    ],
  };

export const CampaignToneDropdownConfig: string[] = [
  'Empathetic',
  'Formal',
  'Casual',
  'Assertive',
  'Instructive',
  'Humorous',
  'Encouraging',
  'Sympathetic',
  'Neutral',
  'Inspiring',
];

export const CampaignPlatformDropdownConfig: string[] = [
  'Facebook',
  'Instagram',
  'SMS',
  //'Tiktok',
  //'Youtube',
];

export const StartCampaignConfig: StartCampaignConfigProps[] = [
  {
    value: 'start-campaign',
    campaignTitle: 'Campaign Leads & Configurations',
    description: 'You can see the campaign information here',
    backButton: null,
    iconName: null,
    settingsButton: true,
    campaignsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'convert-on-the-spot',
    campaignTitle: 'Convert on the Spot',
    description: null,
    backButton: 'start-campaign',
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'choose-media',
    campaignTitle: 'Campaign Leads & Configurations',
    description: 'You can see the campaign information here ',
    backButton: null,
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: false,
    showLeads: false,
  },
  {
    value: 'instagram',
    campaignTitle: 'Instagram',
    description: null,
    backButton: 'start-campaign',
    iconName: 'InstagramIcon',
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: false,
    showLeads: true,
  },
  {
    value: 'facebook',
    campaignTitle: 'Facebook',
    description: null,
    backButton: 'start-campaign',
    iconName: 'FacebookIcon',
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: false,
    showLeads: true,
  },
  {
    value: 'whatsapp',
    campaignTitle: 'WhatsApp',
    description: null,
    backButton: 'start-campaign',
    iconName: 'WhatsappIcon',
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: false,
    showLeads: true,
  },
  {
    value: 'sms',
    campaignTitle: 'SMS',
    description: null,
    backButton: 'start-campaign',
    iconName: 'SMSIcon',
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: false,
    showLeads: true,
  },
  {
    value: 'approved-messages',
    campaignTitle: 'Approved messages',
    description: null,
    backButton: 'start-campaign',
    iconName: null,
    campaignsButton: false,
    settingsButton: false,
    addNewButton: true,
    underLine: false,
    showLeads: true,
  },
  {
    value: 'add-new',
    campaignTitle: 'Add new',
    description: null,
    backButton: 'approved-messages',
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'send-templates',
    campaignTitle: 'Ready to send templates',
    description: null,
    backButton: 'start-campaign',
    iconName: null,
    campaignsButton: false,
    settingsButton: false,
    addNewButton: true,
    underLine: false,
    showLeads: true,
  },
  {
    value: 'edit',
    campaignTitle: 'Edit',
    description: null,
    backButton: 'approved-messages',
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'appointment',
    campaignTitle: 'Nurture To Appointment',
    description: null,
    backButton: 'start-campaign',
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'reminder',
    campaignTitle: 'Reminder',
    description: null,
    backButton: 'start-campaign',
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'custom',
    campaignTitle: 'Custom',
    description: null,
    backButton: 'start-campaign',
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: true,
  },
  {
    value: 'settings',
    campaignTitle: 'Campaign Settings',
    description: 'You can see the campaign information here ',
    backButton: null,
    iconName: null,
    campaignsButton: true,
    settingsButton: true,
    addNewButton: false,
    underLine: true,
    showLeads: false,
  },
];

export const campaignViewValues: CampaignViewValuesProps = {
  startCampaign: 'start-campaign',
  convertOnTheSpot: 'convert-on-the-spot',
  instagram: 'instagram',
  facebook: 'facebook',
  whatsapp: 'whatsapp',
  sms: 'sms',
  approved_messages: 'approved-messages',
  add_new: 'add-new',
  send_templates: 'send-templates',
  edit: 'edit',
  appointment: 'appointment',
  reminder: 'reminder',
  custom: 'custom',
  settings: 'settings',
  chooseMedia: 'choose-media',
};

export const campaignLaunchTimeConfig: string[] = [
  '00:00:00',
  '00:15:00',
  '00:30:00',
  '00:45:00',
  '01:00:00',
  '01:15:00',
  '01:30:00',
  '01:45:00',
  '02:00:00',
  '02:15:00',
  '02:30:00',
  '02:45:00',
  '03:00:00',
  '03:15:00',
  '03:30:00',
  '03:45:00',
  '04:00:00',
  '04:15:00',
  '04:30:00',
  '04:45:00',
  '05:00:00',
  '05:15:00',
  '05:30:00',
  '05:45:00',
  '06:00:00',
  '06:15:00',
  '06:30:00',
  '06:45:00',
  '07:00:00',
  '07:15:00',
  '07:30:00',
  '07:45:00',
  '08:00:00',
  '08:15:00',
  '08:30:00',
  '08:45:00',
  '09:00:00',
  '09:15:00',
  '09:30:00',
  '09:45:00',
  '10:00:00',
  '10:15:00',
  '10:30:00',
  '10:45:00',
  '11:00:00',
  '11:15:00',
  '11:30:00',
  '11:45:00',
  '12:00:00',
  '12:15:00',
  '12:30:00',
  '12:45:00',
  '13:00:00',
  '13:15:00',
  '13:30:00',
  '13:45:00',
  '14:00:00',
  '14:15:00',
  '14:30:00',
  '14:45:00',
  '15:00:00',
  '15:15:00',
  '15:30:00',
  '15:45:00',
  '16:00:00',
  '16:15:00',
  '16:30:00',
  '16:45:00',
  '17:00:00',
  '17:15:00',
  '17:30:00',
  '17:45:00',
  '18:00:00',
  '18:15:00',
  '18:30:00',
  '18:45:00',
  '19:00:00',
  '19:15:00',
  '19:30:00',
  '19:45:00',
  '20:00:00',
  '20:15:00',
  '20:30:00',
  '20:45:00',
  '21:00:00',
  '21:15:00',
  '21:30:00',
  '21:45:00',
  '22:00:00',
  '22:15:00',
  '22:30:00',
  '22:45:00',
  '23:00:00',
  '23:15:00',
  '23:30:00',
  '23:45:00',
];

export const campaignMessageTypesConfig: CampaignMessageTypesConfigProps = {
  convertOnTheSpotType: 'CONVERT_ON_THE_SPOT',
  reminderMessageType: 'SEND_REMINDER_MESSAGE',
  nurtureLeadToAppointmentType: 'NURTURE_LEAD_TO_APPOINTMENT',
};

export const CampaignFilterConfig: CampaignsFilterConfigProps = {
  tags: ['All', 'Issue', 'Feature', 'Planned', 'Sprint', 'Milestone'],
};

export const fileExportTypes = {
  CSV: 'csv',
  EXCEL: 'excel',
};

export const ConversationListStatusOptions = [
  { value: '', text: 'All' },
  { value: 'NEW', text: 'New' },
  { value: 'ACTIVE', text: 'Active' },
  { value: 'STOPPED', text: 'Stopped' },
  { value: 'CLOSED', text: 'Closed' },
];

export const ConversationListSortOptions = [
  { value: 'updatedAtDesc', label: 'Updated At (Newest)' },
  { value: 'updatedAtAsc', label: 'Updated At (Oldest)' },
  { value: 'createdAtDesc', label: 'Created At (Newest)' },
  { value: 'createdAtAsc', label: 'Created At (Oldest)' },
];
