import React, { useRef } from 'react';
import { useAppData } from '../../../context/AppContext';
import { facebookIntegration } from '../../../core/requests/requests';
import { ConnectSocialMediaModalProps } from './startCampaignModels';
import useOutsideClick from '../../../hooks/useOutsideClicks';

const ConnectSocialMediaModal: React.FC<ConnectSocialMediaModalProps> = ({
  onClose,
  isFacebook,
}) => {
  const { currentCampaign, currentStartCampaignView } = useAppData();
  const connectModalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(connectModalRef, () => onClose());

  const handleConnectFacebook = async () => {
    try {
      if (currentCampaign) {
        sessionStorage.setItem(
          'currentStartCampaignView',
          currentStartCampaignView
        );
        const { data } = await facebookIntegration();
        if (data && data.loginUrl) {
          window.location.href = data.loginUrl;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
      <div
        className="d-flex-start-start w-20rem z4 bg-white p-2rem b-radius-1rem"
        ref={connectModalRef}
      >
        <h2>Connect your {isFacebook ? 'Facebook' : 'Instagram'} account?</h2>
        <div className="w-100 d-flex-row-space-between mt-1_5rem">
          <button
            className="btn-subscriptions-secondary w-7rem"
            onClick={onClose}
          >
            No
          </button>
          <button
            className="btn-leads-filter w-7rem"
            onClick={handleConnectFacebook}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectSocialMediaModal;
