import React, { useState, useRef } from 'react';

import { TriangleIcon, FilterIcon, ImportLeadsIcon } from '../../../assets';
import { LeadsHeaderProps } from '../../../core/types/_models';
import { getLeads, importLeadsFromFile } from '../../../core/requests/requests';
import { useAuth } from '../../../context/AuthContext';
import { LeadsHeaderDropdownsConfig } from '../../../config/LeadsConfig';
import SyncLeadsButton from './SyncLeadsButton';
import { useAppData } from '../../../context/AppContext';
import useOutsideClick from '../../../hooks/useOutsideClicks';

const LeadsHeader: React.FC<LeadsHeaderProps> = ({
  toggleFilter,
  leadsState,
  setLeadsState,
}) => {
  const { authLogin } = useAuth();
  const { setLeadsList } = useAppData();
  const accessToken = authLogin?.accessToken;
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDropdown, setSortDropdown] = useState(false);
  const showDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    showDropdownRef,
    () => setSortDropdown(false),
    excludeOutsideClickRef
  );

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]; // Get the first selected file
    if (file && accessToken) {
      try {
        // Call the importLeadsFromFile function with the token and file
        const { data } = await importLeadsFromFile(file);
        if (data) {
          const response = await getLeads(authLogin?.userId);
          if (response?.data) {
            setLeadsList((prevLeads) => {
              if (!prevLeads) return response.data; // return the new leads if prevLeads is null or undefined
              const existingIds = new Set(prevLeads.map((lead) => lead.id));
              const uniqueLeads = response.data.filter(
                (lead) => !existingIds.has(lead.id)
              );
              return [...prevLeads, ...uniqueLeads];
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="d-flex-row-space-between w-100 mb-1_875rem">
      <div className="d-flex-row">
        <h2 className="h2-primary pr-2_25rem">Leads</h2>
        {/*<div
          className="d-flex-row pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span className="f-roboto-secondary fs-1_125rem">Show:</span>
          <div className="d-flex-row  p-relative">
            <span className="f-roboto-m-primary fs-1_125rem pl-0_375rem">
              {leadsState?.showLeadsDropdown}
            </span>
            <div className="d-flex-center pl-0_375rem">
              <TriangleIcon
                className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
              />
            </div>
            {showDropdown && (
              <div className="p-absolute top-1_4rem z1 bg-white">
                {LeadsHeaderDropdownsConfig.showLeads.map((option, index) => (
                  <div
                    className="hovered p-0_2rem-0"
                    key={index}
                    onClick={() => {
                      setLeadsState((prevState) => ({
                        ...prevState,
                        showLeadsDropdown: option,
                      }));
                      setShowDropdown(false);
                    }}
                  >
                    <span className="f-roboto-m-primary fs-1_125rem pl-0_375rem">
                      {option}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>*/}
      </div>
      <div className="d-flex-row">
        <div className="d-flex-row-space-between w-12_5rem h-2_375rem bg-white b-radius-0_5rem p-relative pointer">
          <div
            className="d-flex-row-space-between pl-0_75rem w-100 h-100"
            onClick={() => setSortDropdown(!sortDropdown)}
            ref={excludeOutsideClickRef}
          >
            <span className="sort-title">Sort by:</span>
            <div className="dropdown-wrapper">
              <span className="sort-dropdown-item pl-0_375rem">
                {leadsState?.sortLeadsDropdown}
              </span>
            </div>
            <div className="d-flex-center w-1_75rem h-100 bl-1-l-gray">
              <TriangleIcon
                className={`${sortDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
              />
            </div>
          </div>

          {sortDropdown && (
            <div
              className="p-absolute top-2_375rem z1 bg-white w-100 left-0 shadow-box-12"
              ref={showDropdownRef}
            >
              {LeadsHeaderDropdownsConfig.sortLeadsBy.map((option, index) => (
                <div
                  className="d-flex-center-start h-2_375rem bt-1 hovered"
                  key={index}
                  onClick={() => {
                    setLeadsState((prevState) => ({
                      ...prevState,
                      sortLeadsDropdown: option,
                    }));
                    setSortDropdown(false);
                  }}
                >
                  <span className="sort-dropdown-item pl-0_75rem">
                    {option}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="d-flex-start-start pl-1rem">
          <div
            className="d-flex-center w-2_375rem h-2_375rem bg-white b-radius-0_5rem icon-hovered"
            onClick={toggleFilter}
          >
            <FilterIcon />
          </div>
        </div>
        <SyncLeadsButton />
        {/* Render a file input element */}
        <div className="d-flex-start-start pl-1rem">
          <label htmlFor="file-upload" className="btn-import-leads">
            <div className="d-flex-center mr-0_5rem">
              <ImportLeadsIcon />
            </div>
            <span>Import Leads</span>
          </label>
          {/* Hidden file input */}
          <input
            id="file-upload"
            type="file"
            accept=".csv" // Specify accepted file types if needed
            style={{ display: 'none' }}
            onChange={handleFileChange} // Call handleFileChange on file selection
          />
        </div>
      </div>
    </div>
  );
};

export default LeadsHeader;
