interface DashboardDropdownConfigProps {
  dashboardOptions: string[];
}

interface DashboardSearchDropdownConfigProps {
  searchDropdownOptions: string[];
}

export const DashboardDropdownsConfig: DashboardDropdownConfigProps = {
  dashboardOptions: [
    'This year',
    'This Month',
    'This Week',
    'Today',
    'Custom Date Range',
    /*
    'Today',
    'Yesterday',
    'This Week',
    'Last week',
    'Last 7 days',
    'Last 14 days',
    'Last 28 days',
    'Last 30 days',
    'Last 60 days',
    'Custom Date Range',
    */
  ],
};

export const DashboardSearchDropdownConfig: DashboardSearchDropdownConfigProps =
  {
    searchDropdownOptions: [
      'All',
      'Issue',
      'Feature',
      'Planned',
      'Sprint',
      'Milestone',
    ],
  };

export const ExportStatsTypes = {
  dashboard: 'dashboard',
  analytics: 'analyst',
};
