import React from 'react';
import { IncreaseArrowIcon, InfoIcon } from '../../../../assets';
import { MetaActivityStatsProps } from '../analyticsModels';
import {
  calculateStatisticsPercentage,
  formatStatisticValue,
} from '../../../../helpers/displayDataHelpers';

const MetaActivityStats: React.FC<MetaActivityStatsProps> = ({
  analyticsServicesMetaData,
}) => {
  const messageSentPercentage = calculateStatisticsPercentage(
    analyticsServicesMetaData?.messageSent.value,
    analyticsServicesMetaData?.messageSent.prevValue
  );
  const messageOpenedPercentage = calculateStatisticsPercentage(
    analyticsServicesMetaData?.messageOpened.value,
    analyticsServicesMetaData?.messageOpened.prevValue
  );
  const messageReplyPercentage = calculateStatisticsPercentage(
    analyticsServicesMetaData?.messagesReplyCount.value,
    analyticsServicesMetaData?.messagesReplyCount.prevValue
  );
  const leadCountPercentage = calculateStatisticsPercentage(
    analyticsServicesMetaData?.leadCount.value,
    analyticsServicesMetaData?.leadCount.prevValue
  );

  return (
    <div className="d-flex-start-start bg-white w-calc-33_3_minus_1_3rem b-radius-1_25rem pt-1rem pl-2rem pr-1rem h-100 pb-7_8rem">
      <span className="ff-poppins c-regular lh-1_5rem fw-600 pl-4_5rem ls-0_00625rem">
        Meta
      </span>
      <div className="d-flex-start-start w-100">
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ffcc00 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Leads{' '}
              <span className="fw-700">
                {formatStatisticValue(
                  analyticsServicesMetaData?.leadCount?.value
                )}
              </span>
            </span>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-center mr-0_375rem">
              <IncreaseArrowIcon />
            </div>
            <span className="fs-0_875rem">{leadCountPercentage}</span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: leadCountPercentage }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-075b59 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Reach{' '}
              <span className="fw-700">
                {formatStatisticValue(analyticsServicesMetaData?.reach)}
              </span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-1e92f4 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Warm Leads{' '}
              <span className="fw-700">
                {formatStatisticValue(analyticsServicesMetaData?.warmLead)}
              </span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ff3b30 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Cost Per Leads{' '}
              <span className="fw-700">
                {formatStatisticValue(analyticsServicesMetaData?.costPerLead)}
              </span>
            </span>
          </div>
          <div className="d-flex-center icon-hovered ml-0_5rem tooltip-container">
            <InfoIcon />
            <span className="tooltip-text">Taxes and fees are included</span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: '25%' }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-34c759 w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Messages Sent{' '}
              <span className="fw-700">
                {formatStatisticValue(
                  analyticsServicesMetaData?.messageSent?.value
                )}
              </span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: messageSentPercentage }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ff974a w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Message Opened{' '}
              <span className="fw-700">
                {formatStatisticValue(
                  analyticsServicesMetaData?.messageOpened?.value
                )}
              </span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: messageOpenedPercentage }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-1067db w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Messages Replied{' '}
              <span className="fw-700">
                {formatStatisticValue(
                  analyticsServicesMetaData?.messagesReplyCount?.value
                )}
              </span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{ width: messageReplyPercentage }}
          ></div>
        </div>
        <div className="d-flex-row-space-between w-100 bb-1-e1e1e1 w-100 p-0_5rem-0 mt-0_5rem p-relative">
          <div className="d-flex-row">
            <div className="d-flex-center bg-ca10db w-0_625rem h-0_75rem b-radius-0_313rem mr-0_375rem"></div>
            <span className="fs-0_875rem ls-0_00625rem">
              Conversation Rate{' '}
              <span className="fw-700">{`${analyticsServicesMetaData?.conversationRate ?? 0}%`}</span>
            </span>
          </div>
          <div
            className="d-flex-row w-100 p-absolute left-0 bottom-0 bb-1-1e92f4 z1 bottom-_-0_0625rem"
            style={{
              width: `${analyticsServicesMetaData?.conversationRate ?? 0}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MetaActivityStats;
