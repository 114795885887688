import React, { useState, useEffect, memo, useRef } from 'react';

import { useAppData } from '../../../context/AppContext';
import { getLeadsByCampaignId } from '../../../core/requests/requests';
import { LeadsListProps } from '../../../core/types/_models';
import AddLeadsToCampaignModal from './AddLeadsToCampaignModal';
import useOutsideClick from '../../../hooks/useOutsideClicks';

const StartCampaignTable: React.FC = () => {
  const { setShowModalBackground, currentCampaign } = useAppData();
  const [showAddLeadModal, setShowAddLeadModal] = useState<boolean>(false);
  const [connectedToCampaignLeads, setConnectedToCampaignLeads] = useState<
    LeadsListProps[]
  >([]);
  const addLeadsModalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(addLeadsModalRef, () => handleCloseAddLeadModal());

  const currentCampaignId = currentCampaign?.id;

  const handleOpenAddLeadModal = () => {
    setShowAddLeadModal(true);
    setShowModalBackground(true);
  };

  const handleCloseAddLeadModal = () => {
    setShowAddLeadModal(false);
    setShowModalBackground(false);
  };

  const fetchConnectedToCampaignLeads = async () => {
    if (currentCampaignId) {
      try {
        const { data } = await getLeadsByCampaignId(currentCampaignId);
        if (data) {
          // Remove duplicates by id
          const uniqueLeads: LeadsListProps[] = Array.from(
            data
              .reduce(
                (map: Map<string, LeadsListProps>, obj: LeadsListProps) =>
                  map.set(obj.id, obj),
                new Map<string, LeadsListProps>()
              )
              .values()
          );

          setConnectedToCampaignLeads(uniqueLeads);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchConnectedToCampaignLeads();
  }, []);

  return (
    <div className="d-flex-column w-100">
      <div className="d-flex-row w-100 pt-0_75rem pb-1_75rem bb-1">
        {/*<button className="btn-component-header-secondary mr-1rem">
          Leads
        </button>*/}
        <button
          className="btn-component-header-primary"
          onClick={handleOpenAddLeadModal}
        >
          Add leads
        </button>
      </div>
      {connectedToCampaignLeads?.length > 0 ? (
        <div className="d-flex-start-start overflow-auto w-100">
          <table className="create-campaign-leads-table mt-1_5rem">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Platform</th>
                <th>Acquisition Date</th>
              </tr>
            </thead>
            <tbody>
              {connectedToCampaignLeads.map((lead) => (
                <tr key={lead.id}>
                  <td className="leads-table-name">{`${lead.firstname} ${lead.lastname}`}</td>
                  <td>{lead.email}</td>
                  <td>{lead.phone || 'N/A'}</td>
                  <th>{lead.origin || 'N/A'}</th>
                  <td>{new Date(lead.createdAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex-center w-100 p-1_5rem">
          <span className="fs-1_5rem text-header-description fw-600">
            No leads are currently connected to this campaign.
          </span>
        </div>
      )}
      {showAddLeadModal && (
        <div className="d-flex-center w-100 h-100vh p-fixed z4 top-0 left-0">
          <div className="d-flex-center" ref={addLeadsModalRef}>
            <AddLeadsToCampaignModal
              closeModal={handleCloseAddLeadModal}
              connectedLeads={connectedToCampaignLeads}
              setConnectedToCampaignLeads={setConnectedToCampaignLeads}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(StartCampaignTable);
