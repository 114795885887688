import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  AnalyticsLinearChart,
  AnalyticsHeader,
  EngagementSummary,
  LeadActivityStats,
  VisibilityDrop,
} from './components';
import { AnalyticsStatsDataObjectProps } from '../../core/types/_models';
import { getAnalyticsStats } from '../../core/requests/requests';
import { useAuth } from '../../context/AuthContext';

const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const [analyticsData, setAnalyticsData] =
    useState<AnalyticsStatsDataObjectProps | null>(null);
  const userId = currentUser?.id;

  // Calculate default analytics query string with moment
  const defaultStartDate = moment().startOf('month').format('YYYY-MM-DD');
  const defaultEndDate = moment().format('YYYY-MM-DD');
  const [analyticsQueryString, setAnalyticsQueryString] = useState<string>(
    `?startDate=${defaultStartDate}&endDate=${defaultEndDate}&type=week`
  );

  useEffect(() => {
    const fetchAnalyticsStats = async () => {
      try {
        if (userId) {
          const { data } = await getAnalyticsStats(
            userId,
            analyticsQueryString
          );
          if (data) {
            setAnalyticsData(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAnalyticsStats();
  }, [analyticsQueryString]);

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      {/*<VisibilityDrop />*/}
      <AnalyticsHeader
        setAnalyticsQueryString={setAnalyticsQueryString}
        analyticsQueryString={analyticsQueryString}
      />
      <AnalyticsLinearChart
        analyticsChartData={analyticsData?.graphData ?? []}
      />
      <EngagementSummary analyticsData={analyticsData ?? null} />
      <LeadActivityStats analyticsData={analyticsData ?? null} />
    </div>
  );
};

export default Dashboard;
