import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  biographyValidationSchema,
  cityValidationSchema,
  countryValidationSchema,
  emailValidationSchema,
  feedbackValidationSchema,
  firstNameValidationSchema,
  titleValidationSchema,
} from '../auth/components/ValidationSchemaItems';
import {
  createAgent,
  getAgents,
  updateAgent,
} from '../../core/requests/requests';
import { AgentPayloadProps, AgentProps } from '../../core/types/_models';
import SelectCountryDropdown from '../../components/dropdown/SelectCountryDropdown';
import { InfoModal } from '../../components';

const AdditionalInfoPage: React.FC<{
  setProgress: (value: number) => void;
}> = ({ setProgress }) => {
  const navigate = useNavigate();
  const [currentAgentData, setCurrentAgentData] =
    React.useState<AgentProps | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [focusedFields, setFocusedFields] = useState({
    name: false,
    email: false,
    title: false,
    country: false,
    city: false,
    biography: false,
    feedback: false,
  });

  const initialValues = {
    name: '',
    email: '',
    title: '',
    country: '',
    city: '',
    biography: '',
    feedback: '',
  };

  const handleCloseInfoModal = () => {
    setErrorMessage('');
  };

  const fetchAgents = async () => {
    try {
      const { data } = await getAgents();
      if (data) {
        setCurrentAgentData(data[0]);
        initialValues.name = data[0].name;
        initialValues.email = data[0].email;
        initialValues.title = data[0].title;
        initialValues.country = data[0].country;
        initialValues.city = data[0].city;
        initialValues.biography = data[0].biography;
        initialValues.feedback = data[0].feedback;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAgents();
    setProgress(3);
  }, [setProgress]);

  const validationSchema = Yup.object().shape({
    name: firstNameValidationSchema,
    email: emailValidationSchema,
    title: titleValidationSchema,
    country: countryValidationSchema,
    city: cityValidationSchema,
    biography: biographyValidationSchema,
    feedback: feedbackValidationSchema,
  });

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const handleNavigateToBusinessInfoPage = () => {
    navigate('/additional-business-info');
  };

  const handleSubmit = async (values: AgentPayloadProps) => {
    try {
      if (!currentAgentData) {
        const response = await createAgent(values);
        if (response.data) {
          handleNavigateToBusinessInfoPage();
        }
      } else {
        const agentId = currentAgentData.id;
        const response = await updateAgent(agentId, values);
        if (response.data) {
          handleNavigateToBusinessInfoPage();
        }
      }
    } catch (error: any) {
      if (error.response.data.error) {
        setErrorMessage(error.response.data.error);
      }
      console.log('Updating User Info failed:', error);
    }
  };
  return (
    <div className="d-flex-center w-75 p-relative pb-5rem">
      <div className="d-flex-start-start b-radius-3rem bg-white w-100 mt-8rem p-3_125rem-4_125rem bg-white shadow-box-bottom z1">
        <h3 className="h3-primary mb-1rem ">Create your Agent</h3>
        <p className="p-description mb-2rem">
          This step allows your agent to understand their role in your business,
          so that it can better serve potential customers
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, isValid, setFieldValue, values }) => (
            <Form className="w-100">
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="name"
                    className={`form-control ${errors.name ? 'error-border' : ''} `}
                    placeholder="Name"
                    onFocus={() => handleFieldFocus('name')}
                    onBlur={() => handleFieldBlur('name')}
                  />
                  {errors.name && (
                    <div className="form-error">{errors.name}</div>
                  )}
                  {focusedFields.name && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.name ? 'error-title' : 'correct-title'}`}
                      >
                        Name
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${errors.email ? 'error-border' : ''} `}
                    placeholder="Email Address"
                    onFocus={() => handleFieldFocus('email')}
                    onBlur={() => handleFieldBlur('email')}
                  />
                  {errors.email && (
                    <div className="form-error">{errors.email}</div>
                  )}
                  {focusedFields.email && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.email ? 'error-title' : 'correct-title'}`}
                      >
                        Email Address
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="title"
                    className={`form-control ${errors.title ? 'error-border' : ''} `}
                    placeholder="Title"
                    onFocus={() => handleFieldFocus('title')}
                    onBlur={() => handleFieldBlur('title')}
                  />
                  {errors.title && (
                    <div className="form-error">{errors.title}</div>
                  )}
                  {focusedFields.title && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.title ? 'error-title' : 'correct-title'}`}
                      >
                        Title
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  {/*<Field
                    type="text"
                    name="country"
                    className={`form-control ${errors.country ? 'error-border' : ''} `}
                    placeholder="Country"
                    onFocus={() => handleFieldFocus('country')}
                    onBlur={() => handleFieldBlur('country')}
                  />
                  {errors.country && (
                    <div className="form-error">{errors.country}</div>
                  )}
                  {focusedFields.country && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.country ? 'error-title' : 'correct-title'}`}
                      >
                        Country
                      </span>
                    </div>
                  )}*/}
                  <SelectCountryDropdown
                    setFieldValue={setFieldValue}
                    handleFieldBlur={handleFieldBlur}
                    handleFieldFocus={handleFieldFocus}
                    isCountryFocused={focusedFields.country}
                    errors={errors}
                    countryValue={values.country}
                  />
                </div>
              </div>
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item w-100">
                  <Field
                    type="text"
                    name="city"
                    className={`form-control ${errors.city ? 'error-border' : ''} `}
                    placeholder="City"
                    onFocus={() => handleFieldFocus('city')}
                    onBlur={() => handleFieldBlur('city')}
                  />
                  {errors.city && (
                    <div className="form-error">{errors.city}</div>
                  )}
                  {focusedFields.city && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.city ? 'error-title' : 'correct-title'}`}
                      >
                        City
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 mb-1_75rem">
                <span className="fs-0_875rem">Agent Biography</span>
                <div className="form-item  w-100 mt-0_75rem">
                  <Field
                    type="text"
                    name="biography"
                    component="textarea"
                    className={`textarea-control ${errors.biography ? 'error-border' : ''} `}
                    placeholder="Biography..."
                    onFocus={() => handleFieldFocus('biography')}
                    onBlur={() => handleFieldBlur('biography')}
                  />
                  {errors.biography && (
                    <div className="form-error">{errors.biography}</div>
                  )}
                  {focusedFields.biography && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.biography ? 'error-title' : 'correct-title'}`}
                      >
                        Biography
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 mb-1_75rem">
                <span className="fs-0_875rem">Agent feedback</span>
                <div className="form-item  w-100 mt-0_75rem">
                  <Field
                    type="text"
                    name="feedback"
                    component="textarea"
                    className={`textarea-control ${errors.feedback ? 'error-border' : ''} `}
                    placeholder="Feedback..."
                    onFocus={() => handleFieldFocus('feedback')}
                    onBlur={() => handleFieldBlur('feedback')}
                  />
                  {errors.feedback && (
                    <div className="form-error">{errors.feedback}</div>
                  )}
                  {focusedFields.feedback && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.feedback ? 'error-title' : 'correct-title'}`}
                      >
                        Feedback
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {/*<div className="w-100 mb-2_375rem">
              <span className="fs-0_875rem">Give us some context</span>
              <div className="form-item  w-100 mt-0_75rem">
                <Field
                  type="text"
                  name="campaignContext"
                  component="textarea"
                  className="textarea-control"
                  placeholder="Give us some context - what's your campaign all about?"
                  onFocus={() => handleFieldFocus('campaignContext')}
                  onBlur={() => handleFieldBlur('campaignContext')}
                />
                {focusedFields.about && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">
                      Hello my name...
                    </span>
                  </div>
            )}
              </div>
            </div>*/}
              {/*<SocialMediaConnections />*/}
              <button
                type="submit"
                className="btn btn-light-primary w-100"
                disabled={!isValid}
              >
                Next Step
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="d-flex-start-start p-absolute left-5rem bottom-2rem shadow-box-bottom bg-white h-4rem bbl-radius-1_25rem bbr-radius-1_25rem w-50">
        <div className="w-100 h-1rem"></div>
        <div className="d-flex-center w-100 h-3rem z1">
          <Link to="/auth/login" className="primary-link">
            Already have an account? Log in
          </Link>
        </div>
      </div>
      {errorMessage && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <InfoModal title={errorMessage} onClose={handleCloseInfoModal} />
        </div>
      )}
    </div>
  );
};

export default AdditionalInfoPage;
