import React, { useState, useRef, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { TriangleIcon } from '../../../assets';
import { DashboardDropdownsConfig } from '../../../config/dashboardConfig';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { DateFilterProps } from './conversationModels';
import { set } from 'date-fns';

const DateFilter: React.FC<DateFilterProps> = ({ filters, setFilters }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownState, setDropdownState] = useState('This Month');
  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);

  // Temporary state for the date range selector
  const [tempStartDate, setTempStartDate] = useState<string>(filters.dateStart);
  const [tempEndDate, setTempEndDate] = useState<string>(filters.dateEnd);

  const showDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    showDropdownRef,
    () => setShowDropdown(false),
    excludeOutsideClickRef
  );

  // Handle close date range selector
  const handleCloseDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(false);
  };

  // Function to update the query string
  const updateQueryString = (start: string, end: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateStart: start,
      dateEnd: end,
    }));
  };

  // Handle dropdown selection
  const handleDropdownSelect = (option: string) => {
    setDropdownState(option);
    setShowDropdown(false);

    let start, end, type;

    switch (option) {
      case 'This year':
        start = moment().startOf('year').format('YYYY-MM-DD');
        end = moment().endOf('year').format('YYYY-MM-DD');
        type = 'month';
        break;
      case 'This Month':
        start = moment().startOf('month').format('YYYY-MM-DD');
        end = moment().endOf('month').format('YYYY-MM-DD');
        type = 'week';
        break;
      case 'This Week':
        start = moment().startOf('week').format('YYYY-MM-DD');
        end = moment().endOf('week').format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Today':
        start = moment().format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Custom Date Range':
        setIsDateRangeSelectorOpen(true);
        return; // Skip generating query string until custom date is selected
      default:
        return;
    }
    updateQueryString(start, end);
  };

  // Handle custom date selection
  const handleCustomDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    setTempStartDate(formattedStartDate);
    setTempEndDate(formattedEndDate);
  };

  // Handle saving the selected custom date range
  const handleSaveDateRange = () => {
    updateQueryString(tempStartDate, tempEndDate);
    setIsDateRangeSelectorOpen(false);
  };

  return (
    <>
      <div className="d-flex-row p-relative">
        <span className="ff-inter c-secondary fs-1_125rem lh-1_125rem">
          Show:{' '}
        </span>
        <div
          className="d-flex-row hovered"
          onClick={() => setShowDropdown(!showDropdown)}
          ref={excludeOutsideClickRef}
        >
          <span className="ff-inter c-main fs-1_125rem pl-0_375rem">
            {filters?.dateStart &&
            filters?.dateEnd &&
            dropdownState === 'Custom Date Range'
              ? `${filters?.dateStart} - ${filters?.dateEnd}`
              : dropdownState}
          </span>
          <div className="d-flex-center pl-0_375rem">
            <TriangleIcon
              className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
            />
          </div>
        </div>
        {showDropdown && (
          <div
            className="p-absolute left-_-0_5rem top-1_5rem z1 bg-white mw-12rem shadow-box-12"
            ref={showDropdownRef}
          >
            {DashboardDropdownsConfig.dashboardOptions.map((option, index) => (
              <div
                className="d-flex-center-start h-2_375rem bt-1 hovered"
                key={index}
                onClick={() => handleDropdownSelect(option)}
              >
                <span className="sort-dropdown-item pl-0_75rem">{option}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      {isDateRangeSelectorOpen && (
        <div className="d-flex-center p-absolute right-0 top-2_8rem z4 shadow-box-10 bg-white">
          <DateRange
            ranges={[
              {
                startDate: new Date(tempStartDate || filters.dateStart),
                endDate: new Date(tempEndDate || filters.dateEnd),
                key: 'selection',
              },
            ]}
            onChange={handleCustomDateChange}
          />
          <div className="d-flex-row-space-between w-100 p-1rem">
            <button
              className="btn btn-secondary w-calc-50_minus_0_5rem"
              onClick={handleCloseDateRangeSelector}
            >
              Close
            </button>
            <button
              className="btn btn-primary w-calc-50_minus_0_5rem"
              onClick={handleSaveDateRange}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DateFilter;
