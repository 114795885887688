import React from 'react';

import { ConversationsListProps } from './conversationModels';
import {
  ConversationListFilterDropdown,
  ConversationListSortDropdown,
} from './';

const ConversationsList: React.FC<ConversationsListProps> = ({
  setChats,
  setFilters,
  filters,
  chats,
}) => {
  return (
    <div className="d-flex-row-space-between w-100 mb-1_5rem">
      <ConversationListFilterDropdown
        setFilters={setFilters}
        filters={filters}
      />
      <ConversationListSortDropdown setChats={setChats} chats={chats} />
    </div>
  );
};

export default ConversationsList;
