import React, { useState } from 'react';
import { ConversationFiltersComponentProps } from './conversationModels';
import { getAllChats } from '../../../core/requests/requests';
import { ConversationFiltersProps } from '../../../core/types/_models';

const ConversationFilters: React.FC<ConversationFiltersComponentProps> = ({
  filters,
  setFilters,
  setChats,
}) => {
  const [temporaryFilters, setTemporaryFilters] =
    useState<ConversationFiltersProps>({
      campaignName: filters?.campaignName,
      words: filters?.words,
      conversation: filters?.conversation,
      userName: filters?.userName,
      platforms: filters?.platforms,
      status: filters?.status,
      dateStart: filters?.dateStart,
      dateEnd: filters?.dateEnd,
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTemporaryFilters((prevFilters) => ({
      ...prevFilters, // Keep previous values
      [name]: value, // Update only the changed field
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setTemporaryFilters((prevFilters) => ({
      ...prevFilters,
      platforms: checked
        ? [...prevFilters.platforms, name]
        : prevFilters.platforms.filter((platform) => platform !== name),
    }));
  };

  const fetchAllChats = async (filters: ConversationFiltersProps) => {
    try {
      const { data } = await getAllChats(filters);
      if (data) {
        setChats(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = async () => {
    setFilters(temporaryFilters);
    fetchAllChats(temporaryFilters);
  };

  const handleCancel = async () => {
    setFilters({
      campaignName: '',
      words: '',
      conversation: '',
      userName: '',
      platforms: [],
      status: '',
      dateStart: '',
      dateEnd: '',
    });
    setTemporaryFilters({
      campaignName: '',
      words: '',
      conversation: '',
      userName: '',
      platforms: [],
      status: filters?.status,
      dateStart: filters?.dateStart,
      dateEnd: filters?.dateEnd,
    });
    fetchAllChats(temporaryFilters);
  };

  return (
    <div className="d-flex-start-start w-20rem">
      <div className="d-flex-start-start w-100 pb-2rem bb-1">
        <div className="d-flex-row-space-between w-100 mb-0_75rem">
          <h4 className="h4-primary">Campaign name</h4>
          <span className="ff-poppins fw-500 c-0062ff fs-0_75rem hovered">
            + ADD
          </span>
        </div>
        <input
          className="converstaion-filter-input"
          placeholder="Campaign name"
          name="campaignName"
          value={temporaryFilters.campaignName}
          onChange={handleInputChange}
        />
      </div>
      <div className="d-flex-start-start w-100 p-2rem-0 bb-1">
        <div className="d-flex-row-space-between w-100 mb-0_75rem">
          <h4 className="h4-primary">Words and Phrases </h4>
          <span className="ff-poppins fw-500 c-0062ff fs-0_75rem hovered">
            + ADD
          </span>
        </div>
        <input
          className="converstaion-filter-input"
          placeholder="Ex: pricing I ''price list''"
          name="words"
          value={temporaryFilters.words}
          onChange={handleInputChange}
        />
      </div>
      <div className="d-flex-start-start w-100 p-2rem-0 bb-1">
        <div className="d-flex-row-space-between w-100 mb-0_75rem">
          <h4 className="h4-primary">Conversation Title</h4>
          <span className="ff-poppins fw-500 c-0062ff fs-0_75rem hovered">
            + ADD
          </span>
        </div>
        <input
          className="converstaion-filter-input"
          placeholder="Conversation Title"
          name="conversation"
          value={temporaryFilters.conversation}
          onChange={handleInputChange}
        />
      </div>
      <div className="d-flex-start-start w-100 p-2rem-0 bb-1">
        <div className="d-flex-row w-100 mb-0_75rem">
          <h4 className="h4-primary">Username</h4>
        </div>
        <input
          className="converstaion-filter-input"
          placeholder="Enter username"
          name="userName"
          value={temporaryFilters.userName}
          onChange={handleInputChange}
        />
      </div>
      <div className="d-flex-start-start w-100 p-2rem-0 bb-1">
        <div className="d-flex-row w-100 mb-0_75rem">
          <h4 className="h4-primary">Platforms</h4>
        </div>
        {['Instagram', 'Facebook', 'Google', 'SMS'].map((platform) => (
          <div className="d-flex-row p-0_625rem-0" key={platform}>
            <input
              className="converstaion-filter-checkbox"
              type="checkbox"
              name={platform}
              checked={temporaryFilters.platforms.includes(platform)}
              onChange={handleCheckboxChange}
            />
            <span className="f-roboto-primary pl-0_75rem fs-0_875rem">
              {platform}
            </span>
          </div>
        ))}
        <div className="d-flex-row w-100 mt-2rem w-100">
          <button
            className="btn-conversation-filter-cancel mr-1rem"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button className="btn-conversation-filter" onClick={handleFilter}>
            Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversationFilters;
