import React, { useEffect, useState } from 'react';

import {
  LeadsFilter,
  LeadsHeader,
  LeadsSearchWithDropdown,
  LeadsTable,
  PaginationComponent,
} from './components';
import { useAuth } from '../../context/AuthContext';
import { connectToHubspot, getLeads } from '../../core/requests/requests';
import {
  LeadsFilterStateProps,
  LeadsListProps,
} from '../../core/types/_models';
import { useAppData } from '../../context/AppContext';

const Leads: React.FC = () => {
  const { currentUser, hubSpotAuthCode, setHubSpotAuthCode } = useAuth();
  const { leadsList, setLeadsList } = useAppData();
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [leadsState, setLeadsState] = useState<LeadsFilterStateProps>({
    showLeadsDropdown: 'All leads',
    sortLeadsDropdown: 'Date (newest)',
    filterLeadsTags: [],
    searchQuery: '',
    actionDropdown: 'Action',
    currentPage: 1,
    itemsPerPage: 10,
  });

  const currentUserId = currentUser?.id;

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleCloseFilter = () => {
    setIsFilterVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (hubSpotAuthCode) {
          const data = await connectToHubspot(hubSpotAuthCode);
          if (data && currentUserId) {
            const response = await getLeads(currentUserId);
            if (response) {
              if (response?.data) {
                setLeadsList((prevLeads) => {
                  if (!prevLeads) return response.data; // return the new leads if prevLeads is null or undefined
                  const existingIds = new Set(prevLeads.map((lead) => lead.id));
                  const uniqueLeads = response.data.filter(
                    (lead) => !existingIds.has(lead.id)
                  );
                  return [...prevLeads, ...uniqueLeads];
                });
              }
              setHubSpotAuthCode(null);
            }
          }
        } else if (currentUserId) {
          const response = await getLeads(currentUserId);
          if (response?.data) {
            setLeadsList((prevLeads) => {
              if (!prevLeads) return response.data; // return the new leads if prevLeads is null or undefined
              const existingIds = new Set(prevLeads.map((lead) => lead.id));
              const uniqueLeads = response.data.filter(
                (lead) => !existingIds.has(lead.id)
              );
              return [...prevLeads, ...uniqueLeads];
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [currentUserId, hubSpotAuthCode]);

  const filteredLeads = leadsList?.filter((lead) => {
    // Check if the search query matches any of the lead's properties
    const matchesSearchQuery =
      (lead?.firstname &&
        lead.firstname
          .toLowerCase()
          .includes(leadsState?.searchQuery?.toLowerCase())) ||
      (lead?.lastname &&
        lead.lastname
          .toLowerCase()
          .includes(leadsState?.searchQuery?.toLowerCase())) ||
      (lead?.company &&
        lead.company
          .toLowerCase()
          .includes(leadsState?.searchQuery?.toLowerCase()));

    // Check if the lead's tags match any of the filterLeadsTags
    const matchesTags =
      leadsState?.filterLeadsTags?.length === 0 ||
      leadsState?.filterLeadsTags?.some((tag) =>
        lead?.Tags?.some((leadTag) => leadTag?.name === tag)
      );

    // Return true if both the search query and tags match
    return matchesSearchQuery && matchesTags;
  });

  // Sort leads based on the selected dropdown option
  let sortedLeads: LeadsListProps[] = [];

  if (filteredLeads) {
    sortedLeads = [...filteredLeads];

    if (leadsState.sortLeadsDropdown === 'Date (newest)') {
      sortedLeads.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (leadsState.sortLeadsDropdown === 'Date (oldest)') {
      sortedLeads.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (leadsState.sortLeadsDropdown === 'Name (A-Z)') {
      sortedLeads.sort((a, b) => a.firstname.localeCompare(b.firstname));
    } else if (leadsState.sortLeadsDropdown === 'Name (Z-A)') {
      sortedLeads.sort((a, b) => b.firstname.localeCompare(a.firstname));
    }
  }

  const totalPaginationPages = Math.ceil(
    (sortedLeads?.length || 1) / leadsState?.itemsPerPage
  );

  // Calculate pagination
  const indexOfLastLead = leadsState?.currentPage * leadsState?.itemsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsState?.itemsPerPage + 1;
  const currentLeads = sortedLeads?.slice(
    indexOfFirstLead - 1,
    indexOfLastLead
  );

  return (
    <div className="d-flex-start-start w-100 p-1_875rem ">
      <div className="d-flex-start-start-row w-100">
        <div className="d-flex-start-start w-100">
          <LeadsHeader
            toggleFilter={toggleFilter}
            leadsState={leadsState}
            setLeadsState={setLeadsState}
          />
          <div className="d-flex-start-start w-100 bg-white b-radius-1_125rem p-0_625rem">
            <LeadsSearchWithDropdown
              leadsState={leadsState}
              setLeadsState={setLeadsState}
            />
            {currentLeads && (
              <LeadsTable
                leadsList={currentLeads}
                setLeadsList={setLeadsList}
              />
            )}
            <PaginationComponent
              leadsState={leadsState}
              setLeadsState={setLeadsState}
              totalPaginationPages={totalPaginationPages}
              totalLeadsItems={sortedLeads?.length || 0}
            />
          </div>
        </div>
        {isFilterVisible && (
          <LeadsFilter
            leadsState={leadsState}
            setLeadsState={setLeadsState}
            handleCloseFilter={handleCloseFilter}
          />
        )}
      </div>
    </div>
  );
};

export default Leads;
