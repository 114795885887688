import React, { useState, useRef } from 'react';
import { ChevronDownIcon, InfoIcon } from '../../../../assets';
import {
  convertTimeTo12HourFormat,
  getFormatTimeWithoutPeriod,
  isAM,
} from '../../../../helpers/campaignHelpers';
import { SelectLaunchCampaignDropdownProps } from './campaignSettingsFormModels';
import { campaignLaunchTimeConfig } from '../../../../config/CampaignsConfig';
import useOutsideClick from '../../../../hooks/useOutsideClicks';

const SelectLaunchCampaignDropdown: React.FC<
  SelectLaunchCampaignDropdownProps
> = ({ formikValues, setFormikValues, isCampaignDisabled }) => {
  const [isTimeSelectorOpen, setIsTimeSelectorOpen] = useState(false);
  const timeSelectorRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    timeSelectorRef,
    () => setIsTimeSelectorOpen(false),
    excludeOutsideClickRef
  );
  const toggleTimeDropdown = () => {
    setIsTimeSelectorOpen((prevState) => !prevState);
  };

  const handleChangeLaunchTime = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      campaignLaunch: value,
    }));
    setIsTimeSelectorOpen(false);
  };

  return (
    <div className="d-flex-start-start mr-1rem p-relative">
      <div className="d-flex-row">
        <label className="campaign-label-input">Campaign Launch</label>
        <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
          <InfoIcon />
        </div>
      </div>
      <div
        className={`${isCampaignDisabled ? 'opacity-0_7' : 'pointer'} d-flex-row-space-between campaign-input w-9rem `}
        onClick={() => (!isCampaignDisabled ? toggleTimeDropdown() : () => {})}
        ref={excludeOutsideClickRef}
      >
        <span>{getFormatTimeWithoutPeriod(formikValues?.campaignLaunch)}</span>
        <div className="d-flex-row  z1">
          <span className="text-input-placeholder pr-0_25rem">
            {isAM(formikValues?.campaignLaunch) ? 'AM' : 'PM'}
          </span>
          <ChevronDownIcon
            className={`${isTimeSelectorOpen && 't-rotate-180'} `}
          />
        </div>
      </div>

      {isTimeSelectorOpen && (
        <div
          className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white shadow-box-12"
          ref={timeSelectorRef}
        >
          {campaignLaunchTimeConfig.map((option, index) => (
            <div
              key={index}
              className={`${option === formikValues?.campaignLaunch && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
              onClick={() => handleChangeLaunchTime(option)}
            >
              <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                {convertTimeTo12HourFormat(option)}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLaunchCampaignDropdown;
