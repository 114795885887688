import React, { useState, useEffect } from 'react';

import { ConnectInstagramAdIdProps } from '../startCampaignModels';
import { instagramIntegration } from '../../../../core/requests/requests';
import { InstagramAdsProps } from '../../../../core/types/_models';
import { CloseIcon, SearchIcon } from '../../../../assets';

const ConnectInstagramAdId: React.FC<ConnectInstagramAdIdProps> = ({
  chosenInstagramAdAccountId,
  setChosenInstagramAdAccountId,
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [instagramAdsData, setInstagramAdsData] = useState<InstagramAdsProps[]>(
    []
  );
  const [filteredInstagramAds, setFilteredInstagramAds] =
    useState<InstagramAdsProps[]>(instagramAdsData);

  useEffect(() => {
    const fetchInstagramAds = async () => {
      try {
        const { data } = await instagramIntegration();
        if (data) {
          setInstagramAdsData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchInstagramAds();
  }, []);

  useEffect(() => {
    if (searchInput) {
      setFilteredInstagramAds(
        instagramAdsData.filter((instagramAd) =>
          instagramAd.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    } else {
      setFilteredInstagramAds(instagramAdsData);
    }
  }, [searchInput, instagramAdsData]);

  const handleInstagramAccountAdIdChange = (instagramAdAccountId: string) => {
    if (chosenInstagramAdAccountId === instagramAdAccountId) {
      setChosenInstagramAdAccountId(null);
    } else {
      setChosenInstagramAdAccountId(instagramAdAccountId);
    }
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  return (
    <div className="d-flex-start-start w-100 p-1rem">
      <div className="d-flex-center w-100">
        <div className="d-flex-row w-100 p-relative">
          <input
            className="settings-modal-input"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div className="d-flex-center p-absolute top-0_8rem left-1rem">
            <SearchIcon />
          </div>
          {searchInput.length > 0 && (
            <div
              className="d-flex-center p-absolute top-0_8rem right-1rem icon-hovered"
              onClick={handleClearInput}
            >
              <CloseIcon />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex-start-start w-100">
        {filteredInstagramAds.length > 0 ? (
          filteredInstagramAds
            .filter(
              (InstagramAd) =>
                !chosenInstagramAdAccountId ||
                chosenInstagramAdAccountId === InstagramAd.id
            )
            .map((InstagramAd) => (
              <div
                className={`${
                  chosenInstagramAdAccountId !== null &&
                  chosenInstagramAdAccountId !== InstagramAd.id
                    ? 'c-9ca3af'
                    : ''
                } d-flex-row w-100 p-1rem bb-1 hovered`}
                key={InstagramAd.id}
                onClick={() => handleInstagramAccountAdIdChange(InstagramAd.id)}
              >
                <div className="d-flex-center mr-1rem">
                  <input
                    type="checkbox"
                    className="conversation-filter-checkbox"
                    checked={chosenInstagramAdAccountId === InstagramAd.id}
                  />
                </div>
                <span className="mr-1rem ff-inter c-111928 fs-0_875rem">
                  {InstagramAd.name}
                </span>
              </div>
            ))
        ) : (
          <div className="d-flex-center w-100 p-1rem">
            <span className="ff-inter c-111928 fs-0_875rem">
              No Instagram Ads found
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectInstagramAdId;
