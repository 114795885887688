import React from 'react';
import { SaveIcon, ShareIcon } from '../../../assets';
import { HighlightsHeaderProps } from './conversationModels';

const HighlightsHeader: React.FC<HighlightsHeaderProps> = ({ chatById }) => {
  return (
    <div className="d-flex-row-space-between w-100 bg-white h-4_875rem p-1_875rem bb-1">
      <div className="d-flex-row">
        <div className="d-flex-center bg-a461d8 h-2_25rem w-2_25rem b-radius-1_125rem mr-0_625rem ">
          <span className="ff-poppins fs-0_6875rem color-white fw-500">
            {`${chatById?.chat?.Lead?.firstname?.[0] || ''}${chatById?.chat?.Lead?.lastname?.[0] || ''}`}
          </span>
        </div>
        <div className="d-flex-row pr-0_875rem br-1">
          <span className="ff-poppins c-regular fs-1_125rem">
            <span className=" fw-600">
              Conversation with {chatById?.chat?.Lead?.firstname}{' '}
              {chatById?.chat?.Lead?.lastname}
            </span>
          </span>
        </div>
        <div>
          <span className="ff-roboto c-696974 fs-0_875rem pl-0_625rem ls-0_00625rem">
            Online
          </span>
        </div>
      </div>
      <div className="d-flex-row">
        <div className="d-flex-center icon-hovered mr-1rem">
          <SaveIcon />
        </div>
        <div className="d-flex-center hovered mr-1_75rem">
          <span className="ff-roboto c-0062ff fw-500 ls-0_00625rem lh-1_5rem">
            Add to Library
          </span>
        </div>
        <button className="btn-highlights-share">
          <span>Share</span>
          <div className="d-flex-center ml-0_5rem">
            <ShareIcon />
          </div>
        </button>
      </div>
    </div>
  );
};

export default HighlightsHeader;
