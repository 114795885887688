import React, { useState, useRef } from 'react';

import { ChevronDownIcon, InfoIcon } from '../../../../assets';
import { CampaignTimeZonesDropdownConfig } from '../../../../config/CampaignsConfig';
import { SelectCampaignTimezoneProps } from './campaignSettingsFormModels';
import useOutsideClick from '../../../../hooks/useOutsideClicks';
import { extractTimeZoneValue } from '../../../../helpers/campaignHelpers';

const SelectCampaignTimezone: React.FC<SelectCampaignTimezoneProps> = ({
  setFormikValues,
  formikValues,
  isCampaignDisabled,
}) => {
  const [isTimeZoneDropdownOpen, setIsTimeZoneDropdownOpen] = useState(false);
  const timeZoneOptions = CampaignTimeZonesDropdownConfig.timeZones;
  const timeZoneDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    timeZoneDropdownRef,
    () => setIsTimeZoneDropdownOpen(false),
    excludeOutsideClickRef
  );

  const toggleTimeZoneDropdown = () => {
    setIsTimeZoneDropdownOpen((prevState) => !prevState);
  };

  const handleChangeTimeZone = (selectedTimeZone: number) => {
    setFormikValues((prevState) => ({
      ...prevState,
      timezone: selectedTimeZone,
    }));
    setIsTimeZoneDropdownOpen(false);
  };

  return (
    <div className="d-flex-start-start p-relative w-100">
      <div className="d-flex-row">
        <label className="campaign-label-input">Timezone</label>
        <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
          <InfoIcon />
        </div>
      </div>
      <div
        className={`${isCampaignDisabled ? 'opacity-0_7' : 'pointer'} d-flex-row campaign-input w-100 mw-9rem `}
        onClick={() =>
          !isCampaignDisabled ? toggleTimeZoneDropdown() : () => {}
        }
        ref={excludeOutsideClickRef}
      >
        <span>
          {extractTimeZoneValue(
            CampaignTimeZonesDropdownConfig,
            formikValues?.timezone
          )}
        </span>
        <div className="d-flex-center p-absolute right-0_625rem top-2_8rem">
          <ChevronDownIcon
            className={`${isTimeZoneDropdownOpen && 't-rotate-180'}`}
          />
        </div>
      </div>
      {isTimeZoneDropdownOpen && (
        <div
          className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white shadow-box-12"
          ref={timeZoneDropdownRef}
        >
          {timeZoneOptions.map((option, index) => (
            <div
              key={index}
              className={`${
                option.value === formikValues?.timezone && 'bg-badge'
              } d-flex-center-start h-2_375rem bb-1 hovered`}
              onClick={() => handleChangeTimeZone(option?.value)}
            >
              <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                {option.name} ({option.timeZone})
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCampaignTimezone;
