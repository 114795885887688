import React, { useState, useRef } from 'react';
import { ExportIcon, VerticalDotsIcon } from '../../../assets';
import SelectCampaignModal from './SelectCampaignModal';
import { useAppData } from '../../../context/AppContext';
import { fileExportTypes } from '../../../config/CampaignsConfig';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import DateFilter from './DateFilter';
import { ConversationsHeaderProps } from './conversationModels';

const ConversationsHeader: React.FC<ConversationsHeaderProps> = ({
  filters,
  setFilters,
}) => {
  const { setShowModalBackground } = useAppData();
  const [exportFileType, setExportFileType] = useState<string>('');
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const [showSelectCampaignModal, setShowSelectCampaignModal] = useState(false);

  const showDropdownRef = useRef<HTMLDivElement>(null);
  const showModalRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  const handleOpenSelectCampaignModal = () => {
    setShowSelectCampaignModal(true);
    setShowModalBackground(true);
  };

  const closeSelectCampaignModal = () => {
    setShowSelectCampaignModal(false);
    setShowModalBackground(false);
  };

  useOutsideClick(
    showDropdownRef,
    () => setShowExportDropdown(false),
    excludeOutsideClickRef
  );

  useOutsideClick(showModalRef, () => closeSelectCampaignModal());

  const triggerExportDropdown = () =>
    setShowExportDropdown(!showExportDropdown);

  const closeExportDropdown = () => {
    setShowExportDropdown(false);
    handleOpenSelectCampaignModal();
  };

  const handleSelectExportFileType = (type: string) => {
    setExportFileType(type);
    closeExportDropdown();
  };
  return (
    <div className="d-flex-row-space-between w-100 mb-2rem">
      <div className="d-flex-start-baseline-row">
        <h2 className="h2-primary pr-1_375rem">Conversations</h2>
        <DateFilter filters={filters} setFilters={setFilters} />
      </div>
      <div className="d-flex-row w-10rem p-relative ">
        <div
          className="d-flex-center-end-row w-100"
          onClick={triggerExportDropdown}
          ref={excludeOutsideClickRef}
        >
          <ExportIcon />
          <span className="hovered p-0_5rem c-1751d0 fs-0_875rem fw-500">
            Export
          </span>
          <VerticalDotsIcon className="icon-hovered" />
        </div>
        {showExportDropdown && (
          <div
            className="d-flex-start-start w-100 p-absolute top-2_3rem b-radius-0_5rem b-1-light-gray shadow-box-12 bt-1 bg-white p-0_5rem-0"
            ref={showDropdownRef}
          >
            <div
              className="d-flex-row w-100 p-0_5rem-1rem hovered"
              onClick={() => handleSelectExportFileType(fileExportTypes?.EXCEL)}
            >
              <span className="c-696974 fs-0_875rem">Export to Excel</span>
            </div>
            <div
              className="d-flex-row w-100 p-0_5rem-1rem hovered bt-1"
              onClick={() => handleSelectExportFileType(fileExportTypes?.CSV)}
            >
              <span className="c-696974 fs-0_875rem">Export to CSV</span>
            </div>
          </div>
        )}
      </div>

      {showSelectCampaignModal && (
        <div className="d-flex-center h-100vh w-100 p-fixed left-0 top-0 z4">
          <div className="d-flex-center" ref={showModalRef}>
            <SelectCampaignModal
              exportFileType={exportFileType}
              closeModal={closeSelectCampaignModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationsHeader;
